import React, { ButtonHTMLAttributes } from "react";
import styled from "styled-components";

import { Device } from "@/utils/device";
import colours from "../../utils/colours";

type StyleProps = {
  animationColour?: string;
  colour?: string;
  textColour?: string;
};

interface MarketingButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  name: string;
  animationColour?: string;
  children?: string;
  colour?: string;
  buttonText?: string;
  textColour?: string;
  type?: "button" | "submit" | "reset";
}

const MarketingButton: React.FC<MarketingButtonProps> = (props) => {
  return (
    <S.ContentContainer
      colour={props.colour}
      animationColour={props.animationColour}
      tabIndex={0}
      textColour={props.textColour}
    >
      <S.Button
        name={props.name}
        type={props.type}
        data-cy={`${props.name}-button`}
        {...props}
      >
        {props.buttonText || props.children}
        <S.Arrow colour={props.colour} />
      </S.Button>
    </S.ContentContainer>
  );
};

const S = () => {};

S.ContentContainer = styled.div<StyleProps>`
  height: 20px;
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  gap: 50px;
  cursor: pointer;
  color: ${(p) => p.textColour || colours.charcoal};

  &::before {
    background: ${(p) => p.colour || colours.charcoal};
    bottom: -5px;
    content: "";
    display: inline-block;
    height: 2px;
    left: 0;
    position: absolute;
    transition: all 0.3s ease-out;
    width: 100%;

    @media (min-width: ${Device.mobile}) {
      bottom: -10px;
    }
  }

  &::after {
    background: ${(p) => p.animationColour || colours.shellOverlay};
    bottom: -5px;
    content: "";
    display: inline-block;
    height: 2px;
    left: 0;
    position: absolute;
    transition: all 0.3s ease-out;
    width: 0;

    @media (min-width: ${Device.mobile}) {
      bottom: -10px;
    }
  }

  &:hover::after {
    width: 100%;
  }
`;

S.Button = styled.button<StyleProps>`
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 700;
  color: ${(p) => p.textColour || colours.charcoal};

  @media (min-width: ${Device.mobile}) {
    font-size: inherit;
  }
`;

S.Arrow = styled.span<StyleProps>`
  display: inline-block;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 9px solid ${(p) => p.colour || colours.charcoal};
  margin-left: 15px;
`;

export default MarketingButton;
