import React from "react";
import * as Router from "next/router";
import styled, { keyframes } from "styled-components";

import * as Icons from "./Icons";
import * as T from "./Typography";
import * as u from "../utils";
import Button from "./Button";
import Image from "next/image";

export default function Modal(props) {
  const router = Router.useRouter();

  const innerRef = React.useRef(null);
  React.useEffect(attachOutsideClick, []);
  React.useEffect(attachEscKey, []);
  React.useEffect(onBlockScroll, []);

  return (
    <S.Container data-cy="upsell-modal">
      <S.InnerContainer
        colour={props.colour}
        ref={innerRef}
        width={props.width}
      >
        <S.CloseContainer onClick={onClose} data-cy={"close-upsell-modal"}>
          <Icons.Close grow={1.3} />
        </S.CloseContainer>
        <S.BodyContainer>
          <S.HeaderContainer>
            <T.H2>{props.title}</T.H2>
            {(props.isUpsell || props.image) && (
              <Image
                src={`/images/${props.image}`}
                alt={`${props.imageAltText}`}
                height={"200px"}
                width={"200px"}
                objectFit="cover"
              />
            )}
          </S.HeaderContainer>
          {props.children}
        </S.BodyContainer>
        {props.buttonText && (
          <S.ButtonContainer>
            {props.isUpsell && (
              <T.CM>
                Already a member?{" "}
                <T.A onClick={() => router.push("/auth/login")}>Log in</T.A>
              </T.CM>
            )}
            <S.Button
              onClick={props.onProceed}
              text={props.buttonText}
              colour={props.buttonColour || u.colours.purple}
              isWide
              data-cy={"modal-button"}
            />
            <S.DismissContainer onClick={onClose} data-cy={"not-now"}>
              <T.CM fontStyle={"italic"}>
                {props.type === "digital" && "Maybe later"}
                {props.type === "club" && "Not today, thank you"}
              </T.CM>
            </S.DismissContainer>
          </S.ButtonContainer>
        )}
      </S.InnerContainer>
    </S.Container>
  );

  function onBlockScroll() {
    document.body.setAttribute("style", "overflow: hidden;");
    return () => document.body.setAttribute("style", "overflow: visible;");
  }

  function attachOutsideClick() {
    document.addEventListener("click", onMaybeOutside);
    return () => document.removeEventListener("click", onMaybeOutside);

    function onMaybeOutside(evt) {
      if (innerRef.current?.contains(evt.target)) return;
      onClose();
    }
  }

  function attachEscKey() {
    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);

    function onKeyDown(evt) {
      if (evt.key === "Escape") onClose();
      return;
    }
  }

  function onClose() {
    if (props.onClose) props.onClose();
  }
}

const S = {};

S.Fade = keyframes`
  100% {opacity: 1}
`;

S.Container = styled.div`
  animation: ${S.Fade} 0.2s ease-out forwards;
  z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: hsla(0, 0%, 0%, 0.5);
  display: flex;
  opacity: 0;
  overflow: auto;
`;

S.InnerContainer = styled.div`
  background-color: ${(p) => p.colour ?? "white"};
  border-radius: 3px;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 12px 28px 0px,
    rgba(0, 0, 0, 0.1) 0px 2px 4px 0px,
    rgba(255, 255, 255, 0.5) 0px 0px 0px 1px inset;
  display: grid;
  grid-template-columns: auto 60px;
  grid-template-rows: min-content;
  grid-template-areas:
    "header   close  "
    "content content"
    "button  button ";
  margin: auto;
  width: ${(p) => p.width ?? "500px"};

  @media ${u.device.tablet} {
    grid-template-columns: auto 84px;
    max-width: ${(p) => p.width ?? "550px"};
    margin: 2em auto;
  }
`;

S.CloseContainer = styled.div`
  align-items: center;
  display: flex;
  grid-area: close;
  cursor: pointer;
  justify-content: center;
  height: 35px;
  padding-top: 20px;

  @media ${u.device.tablet} {
    padding-top: 30px;
  }
`;

S.BodyContainer = styled.div`
  grid-area: content;
`;

S.HeaderContainer = styled.div`
  text-align: center;
  letter-spacing: 3px;
  width: fit-content;
  margin: auto;
  > * :not(:last-child) {
    padding-bottom: 10px;
  }
`;

S.ButtonContainer = styled.div`
  text-align: center;
  grid-area: button;
  padding: 0 20px 20px;
`;

S.Button = styled(Button)`
  margin: auto;
  margin-bottom: 10px;
  margin-top: 10px;
`;

S.DismissContainer = styled.div`
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  margin: auto;
`;
