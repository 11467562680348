import React, { AnchorHTMLAttributes } from "react";
import styled from "styled-components";
import Link from "next/link";

import { A } from "../../Typography";
import { colours } from "@/utils";

// Use textLink attr if text is first child of this Component.
// Otherwise use T.Animation at source as a wrapper for text.

interface RxLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
  disabled?: boolean;
  textLink?: boolean;
  textColour?: string;
}

const RxLink: React.FC<RxLinkProps> = (props) => {
  return (
    <S.Link
      href={props.href}
      passHref
      disabled={props.disabled || false}
      aria-disabled={props.disabled}
      {...props}
    >
      {props.textLink ? (
        <A
          data-cy="link-text"
          colour={props.textColour || colours.teal}
          className={props.className}
        >
          {props.children}
        </A>
      ) : (
        <S.A
          disabled={props.disabled || false}
          aria-disabled={props.disabled}
          href={props.href}
          data-cy="link-child"
          className={props.className}
        >
          {props.children}
        </S.A>
      )}
    </S.Link>
  );
};

type StyleProps = {
  disabled?: boolean;
};

const S = () => {};

S.Link = styled(Link)<StyleProps>`
  ${(p) =>
    p.disabled &&
    `
    pointer-events: none;
  `}
`;

S.A = styled.a<StyleProps>`
  border-radius: 20px;
  color: inherit;
  text-decoration: inherit;

  ${(p) =>
    p.disabled &&
    `
  pointer-events: none;
`}
`;

export default RxLink;
