import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { colours } from "@/utils";
import { Device } from "@/utils/device";
import RxTabs from "../ui/display/RxTabs";

type StyleProps = {
  background?: string;
  hasShadow?: boolean;
};

type FormContainerProps = {
  tabs: {
    title: string;
    content: React.ReactNode;
  }[];
  backgroundColour?: string;
  hasShadow?: boolean;
  initialTab?: string;
};

const MarketingFormContainer: FunctionComponent<FormContainerProps> = (
  props
) => {
  const contentRef = React.useRef(null);
  const [currentTab, setCurrentTab] = React.useState(
    props.initialTab || props.tabs[0].title
  );

  return (
    <S.Container>
      <RxTabs
        currentTab={currentTab}
        hasShadow={props.hasShadow}
        setCurrentTab={setCurrentTab}
        tabs={props.tabs}
      />
      <S.FormContainer
        hasShadow={props.hasShadow}
        background={props.backgroundColour}
      >
        {props.tabs.map((tab) => (
          <>
            {currentTab === tab.title && (
              <div ref={contentRef}>{tab.content}</div>
            )}
          </>
        ))}
      </S.FormContainer>
    </S.Container>
  );
};

const S = () => {};

S.Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${Device.mobile}) {
  }

  @media (min-width: ${Device.desktop}) {
    padding: 0;
    margin: 0;
  }
`;

S.FormContainer = styled.div<StyleProps>`
  margin: 0 0 3rem 0;
  width: 100%;
  padding: 2rem 3rem 3rem 3rem;
  background: ${colours.shellHighlight};

  ${(p) =>
    p.hasShadow &&
    `
    border-radius: 0 0 5px 5px;
    box-shadow: 0px 4px 4px 0px #C3AFA1;
  `}
`;

export default MarketingFormContainer;
