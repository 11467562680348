import React, { FunctionComponent, InputHTMLAttributes, useState } from "react";
import styled from "styled-components";

import colours from "../../../utils/colours";
import { Device } from "@/utils/device";
import { errorOutline } from "@/utils/errorOutline";

interface RxPhoneNumberInputProp extends InputHTMLAttributes<HTMLInputElement> {
  labelText: string;
  name: string;
  error?: boolean;
  errorRed?: boolean;
}

const RxPhoneNumberInput: FunctionComponent<RxPhoneNumberInputProp> = (
  props
) => {
  const [isFocus, setIsFocus] = useState(false);
  const shrinkLabel = props.value ? true : isFocus;

  return (
    <S.Container
      data-cy={`${props.name}-phoneNumber-input`}
      onBlur={() => setIsFocus(false)}
      onFocus={() => setIsFocus(true)}
    >
      <S.Label $shrinkLabel={shrinkLabel} htmlFor={props.name}>
        {props.labelText}
      </S.Label>
      <S.Input
        {...props}
        type="tel"
        inputMode="numeric"
        pattern="[0-9]*"
        id={props.name}
        aria-invalid={props.error}
      />
    </S.Container>
  );
};

type StyleProps = {
  error?: boolean;
  $shrinkLabel?: boolean;
};

const S = () => {};

S.Container = styled.div`
  position: relative;
`;

S.Label = styled.label<StyleProps>`
  display: block;
  color: ${colours.charcoal};
  font-size: 1.4rem;
  font-weight: 300;
  left: 16px;
  letter-spacing: 0.5px;
  position: absolute;
  top: 16px;
  transition: all 0.1s ease-out;

  &:hover {
    cursor: pointer;
  }

  ${(p) =>
    p.$shrinkLabel &&
    `
    letter-spacing: 0px;
    font-size: 8px;
    left: 16px;
    top: 8px;
  `}

  @media (min-width: ${Device.mobile}) {
    font-size: 1.6rem;
    top: 20px;

    ${(p) =>
      p.$shrinkLabel &&
      `
      font-size: 12px;
      top: 8px;
    `}
  }
`;

S.Input = styled.input<StyleProps>`
  align-items: center;
  border: 1px solid ${colours.shellOverlay};
  border-radius: 5px;
  color: ${colours.charcoal};
  display: flex;
  font-size: 1.4rem;
  font-weight: 500;
  height: 49px;
  letter-spacing: 0.5px;
  outline: none;
  padding-left: 14px;
  padding-top: 10px;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    border: 1px solid black;
  }

  ${(p) =>
    p.error &&
    `
    border: none;
    ${errorOutline}
  `}

  @media(min-width: ${Device.mobile}) {
    height: 59px;
    font-size: 1.6rem;
  }
`;

export default RxPhoneNumberInput;
