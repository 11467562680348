import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { Device } from "@/utils/device";
import { colours } from "@/utils";

type StyleProps = {
  activeColour?: string;
  activeTextColour?: string;
  backgroundColour?: string;
  currentTab?: string;
  inactiveTextColour?: string;
  isActive?: boolean;
  hasShadow?: boolean;
  tabWidth?: string;
};

type RxTabsProps = {
  currentTab: string;
  setCurrentTab: (string) => void;
  tabs: {
    title: string;
    activeColour?: string;
    activeTextColour?: string;
    backgroundColour?: string;
    inactiveTextColour?: string;
    name?: string;
  }[];
  hasShadow?: boolean;
};

const RxTabs: FunctionComponent<RxTabsProps> = (props) => {
  const tabWidth = `${100 / props.tabs.length}%`;

  return (
    <S.Container
      currentTab={props.currentTab}
      data-cy="tabs-container"
      hasShadow={props.hasShadow}
    >
      {props.tabs.map((tab) => (
        <>
          <S.Tab
            activeColour={tab.activeColour || colours.marketingBlue}
            data-cy={`tab-${tab.name || tab.title}`}
            backgroundColour={tab.backgroundColour || colours.shellOverlay}
            isActive={props.currentTab === tab.title}
            onClick={() => props.setCurrentTab(tab.title)}
            role="tab"
            tabIndex={0}
            tabWidth={tabWidth}
          >
            <S.TabLabel
              activeTextColour={tab.activeTextColour || colours.shell}
              inactiveTextColour={tab.inactiveTextColour || colours.charcoal}
              isActive={props.currentTab === tab.title}
            >
              {tab.title}
            </S.TabLabel>
          </S.Tab>
        </>
      ))}
    </S.Container>
  );
};

const S = () => {};

S.Container = styled.div<StyleProps>`
  width: 100%;
  overflow: hidden;
  font-weight: 700;
  display: flex;

  ${(p) =>
    p.hasShadow &&
    `
    border-radius: 5px 5px 0 0;
    box-shadow: 0px 4px 4px 0px #C3AFA1;
  `}
`;

S.Tab = styled.button<StyleProps>`
  width: ${(p) => p.tabWidth};
  border: none;
  background: ${(p) => (p.isActive ? p.activeColour : p.backgroundColour)};
  padding: 1.5rem 1rem;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  transition: all 0.3s ease-out;

  &:focus-visible {
    border: 0.2rem solid ${colours.marketingBlue};
  }

  &:hover {
    filter: brightness(0.9);
  }

  ${(p) =>
    p.isActive &&
    `
    background: ${(p) => p.backgroundColour};
    pointer-events: none;
  `}

  @media(min-width: ${Device.tablet}) {
    padding: 2rem 1rem;
  }
`;

S.TabLabel = styled.p<StyleProps>`
  margin: 0 auto;
  width: fit-content;
  position: relative;
  color: ${(p) => (p.isActive ? p.activeTextColour : p.inactiveTextColour)};
`;

export default RxTabs;
